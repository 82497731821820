<template>
  <div class="bg-white">
    <div
        v-if="overlayPopup"
        class="z-1 top-0 fixed left-0 right-0 h-screen bg-overlay bottom-0"
    ></div>
    <div class="relative w-full bg-white z-3">
      <div class="custom-container">
        <div class="grid grid-cols-12">
          <div class="col-start-0 md:col-start-3 col-span-12 md:col-span-8">
            <div class="flex justify-between items-center px-3 sm:px-0 py-2">
              <div class="relative">
                <div class="flex items-center cursor-pointer" @click="navigateToHome">
                  <niceone-image
                      :src="locale === 'ar' ? $publicPath('/images/rtl_logo.svg') : $publicPath('/images/ltr_logo.svg')"
                      class="w-[115px]"/>
                </div>
              </div>
              <div class="relative">
                <ul class="flex items-center justify-center pt-[7px] gap-6">
                  <li
                      class="flex items-center justify-center group hover:border-b-black border-b-3 border-white"
                      @mouseleave="overlayPopup = false"
                      @mouseover="overlayPopup = true"
                  >
                    <Language/>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import Language from "./common/Langauge.vue";
import useCustomConfig from "@/composables/useEnvConfig";

const {locale, t} = useI18n()
const overlayPopup = ref(false);

const navigateToHome = () => {
  window.location.href = getHomeUrl()
}

const getHomeUrl = () => {
  const config = useCustomConfig()
  const appEnv = config.public.APP_ENV

  switch (appEnv) {
    case 'local':
      return 'http://localhost:3000'
    case 'test':
      return 'https://test2.niceonesa.com'
    default:
      return 'https://niceonesa.com'
  }
}
</script>

<style lang="scss" scoped></style>